<template>
  <div class="region-container">
    <div class="header">
      <div class="title">区域门店</div>
    </div>
    <div class="content">
      <el-input placeholder="门店名称" v-model="regionKeyword" size="small" :clearable="true" :style="{marginBottom: '12px'}">
        <el-button slot="append" icon="el-icon-search" size="mini" @click="search"></el-button>
      </el-input>
      <el-checkbox v-model="checkAllStore" @change="handleAllChange" :style="{marginLeft: '25px',marginBottom: '5px'}">全部</el-checkbox>
      <div class="treeContainer">
        <el-tree
          v-loading="loading"
          :data="storeData"
          show-checkbox
          @check="handleCheck"
          node-key="NODE_CODE"
          ref="storeTree"
          :props="treeProps"
          :default-expanded-keys="expandedKeys"
        >
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrderStore} from "@/api/delivery";

export default {
  name: "SelectRegion",
  props: {
    // 初始值
    rStore: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      loading:false,
      regionKeyword:'',
      checkAllStore:true,
      storeData: [],
      treeProps: {
        children: 'children',
        label: 'NODE_NAME'
      },
      firstLoad:false,
      expandedKeys: this.rStore?[this.rStore]:[],
    };
  },
  created() {
  },
  mounted() {
    if(this.rStore){
      this.firstLoad = true;
    }
    this.getRegionStoreList();
  },
  methods: {
    getRegionStoreList() {
      this.loading = true;
      getOrderStore({}).then(result => {
        if (result) {
          this.checkAllStore = true;
          this.loading = false;
          if(this.firstLoad){
            this.storeData = result.data;
            this.$refs.storeTree.setCheckedKeys([this.rStore]);
            this.$emit('selectStore', [this.rStore]);
            this.firstLoad = false;
          }else{
            if (this.regionKeyword) {
              const page = this;
              page.storeData = page.searchJsonArrayOptimized(result.data, page.regionKeyword);
              setTimeout(function() {
                page.$refs.storeTree.setCheckedKeys(page.storeData.map(item => item.NODE_CODE));
                page.$emit('selectStore', page.$refs.storeTree.getCheckedKeys());
              }, 10);
            } else {
              this.storeData = result.data;
              this.$refs.storeTree.setCheckedKeys(this.storeData.map(item => item.NODE_CODE));
              this.$emit('selectStore', []);
            }
          }
        }
      });
    },
    search() {
      this.getRegionStoreList();
    },
    handleAllChange(checkAll) {
      if (checkAll) {
        this.$refs.storeTree.setCheckedKeys(this.storeData.map(item => item.NODE_CODE));
      } else {
        this.$refs.storeTree.setCheckedKeys([]);
      }
      this.$emit('selectStore', (checkAll && !this.regionKeyword) ? [] : (this.$refs.storeTree.getCheckedKeys().length === 0 ? ["-1"] : this.$refs.storeTree.getCheckedKeys()));
    },
    handleCheck(data, treeData) {
      this.checkAllStore = this.storeData.map(item => item.NODE_CODE).every(node => new Set(this.$refs.storeTree.getCheckedKeys()).has(node));
      this.$emit('selectStore', (this.checkAllStore && !this.regionKeyword) ? [] : (this.$refs.storeTree.getCheckedKeys().length === 0 ? ["-1"] : this.$refs.storeTree.getCheckedKeys()));
    },
    searchJsonArrayOptimized(jsonArray, key) {
      const recursiveSearch = (nodes) => {
        let result = [];
        for (let node of nodes) {
          if (node.NODE_LEVEL === undefined && node.NODE_NAME && node.NODE_NAME.includes(key)) {
            let newNode = { ...node };
            if (newNode.children && newNode.children.length > 0) {
              newNode.children = recursiveSearch(newNode.children);
              if (newNode.children.length === 0) {
                delete newNode.children;
              }
            }
            result.push(newNode);
          } else if (node.children && node.children.length > 0) {
            let filteredChildren = recursiveSearch(node.children);
            if (filteredChildren.length > 0) {
              let updatedNode = { ...node, children: filteredChildren };
              result.push(updatedNode);
            }
          }
        }
        return result;
      }
      return recursiveSearch(jsonArray);
    },
  },
};
</script>

<style lang="scss" scoped>
.region-container{
  float: left;
  width: 220px;
  height: 100%;
  margin-right: 20px;
  border: 1px solid #ddd;
  .header{
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom:1px solid #ddd;
    padding-left: 15px;
    .title{
      width: 60px;
      height: 100%;
      border-bottom: 2px solid #409EFF;
    }
  }
  .content{
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 10px;
  }
}
.treeContainer{
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
}
</style>
