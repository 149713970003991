var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "region-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-input",
          {
            style: { marginBottom: "12px" },
            attrs: { placeholder: "门店名称", size: "small", clearable: true },
            model: {
              value: _vm.regionKeyword,
              callback: function ($$v) {
                _vm.regionKeyword = $$v
              },
              expression: "regionKeyword",
            },
          },
          [
            _c("el-button", {
              attrs: { slot: "append", icon: "el-icon-search", size: "mini" },
              on: { click: _vm.search },
              slot: "append",
            }),
          ],
          1
        ),
        _c(
          "el-checkbox",
          {
            style: { marginLeft: "25px", marginBottom: "5px" },
            on: { change: _vm.handleAllChange },
            model: {
              value: _vm.checkAllStore,
              callback: function ($$v) {
                _vm.checkAllStore = $$v
              },
              expression: "checkAllStore",
            },
          },
          [_vm._v("全部")]
        ),
        _c(
          "div",
          { staticClass: "treeContainer" },
          [
            _c("el-tree", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "storeTree",
              attrs: {
                data: _vm.storeData,
                "show-checkbox": "",
                "node-key": "NODE_CODE",
                props: _vm.treeProps,
                "default-expanded-keys": _vm.expandedKeys,
              },
              on: { check: _vm.handleCheck },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("区域门店")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }